import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layouts/layout"
import * as myModule from "../components/dynamic"
import Seo from "../components/Seo/seo"

function ContactPage({ data }) {
  const wpData = data.wpPage.sections.blocks
  const seo = data?.wpPage?.seo
  return (
    <Layout>
      <Seo
        title={seo.title}
        description={seo.metaDesc}
        featuredImage={
          seo.opengraphImage?.localFile.childImageSharp.gatsbyImageData
        }
      />
      <div id="contact-page">
        {wpData.map(row => {
          // assign component math with name
          const Component = myModule[row.fieldGroupName.split("_").slice(-1)[0]]
          return <Component key={row.fieldGroupName} {...row} />
        })}
      </div>
    </Layout>
  )
}

export default ContactPage

export const query = graphql`
  query {
    wpPage(id: { eq: "cG9zdDoyOTM=" }) {
      seo {
        ...SeoData
      }
      sections {
        blocks {
          ... on WpPage_Sections_Blocks_PageHeader {
            fieldGroupName
            title
            subtitle
            phone {
              title
              url
            }
            address {
              title
              url
              target
            }
            button {
              title
              url
              target
            }
            box {
              title
              link {
                title
                url
                target
              }
            }
            contactSections {
              title
              phones {
                title
                phone
              }
              addresses {
                title
                address
              }
              buttons {
                button {
                  title
                  url
                  target
                }
              }
            }
          }
          ... on WpPage_Sections_Blocks_CtaWithForm {
            fieldGroupName
            title
            subTitle
            form
            backgroundImage {
              sourceUrl
              localFile {
                childImageSharp {
                  fluid(fit: COVER, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            logo {
              sourceUrl
            }
          }
        }
      }
    }
  }
`
